.footer-links{
  a:hover{
    text-decoration: none;
    color: $primary;
  }
  a{
    color: $white;
  }
}

.carousel-indicators li{
  background-color: #BEBEBE;
}

.carousel-indicators li.active{
  background-color: $primary;
}
